import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { USER } from '../models/user.modal';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private storageKey:string = 'abcNGFxyz-Token';
  private userKey:string = 'abcNGFxyz-User';

  private currentUserSubject!: BehaviorSubject<USER | null>;
  currentUser! : Observable<USER | null>;
  isLoggedIn:boolean = false;

  constructor(
    private router: Router,
    private toastr: ToastrService
  ) {}

  checkUser():boolean{
    let val:any = this.getUserFromStorage,
    user:any;
    if(val) user = JSON.parse(val);
    if(user && user?.token){
      this.isLoggedIn = true;
      this.currentUserSubject = new BehaviorSubject<USER | null>(user);
      this.currentUser = this.currentUserSubject.asObservable();
      return this.checkTokenExpired(jwtDecode(user.token));
    }
    else{
      this.isLoggedIn = false;
      this.currentUserSubject = new BehaviorSubject<USER | null>(null);
      this.currentUser = this.currentUserSubject.asObservable();
      return true;
    }
  }

  saveUserAndPassword(loginData:any){
    if(loginData?.email && loginData?.password){
      let user = btoa(JSON.stringify(loginData));
      localStorage.setItem(this.userKey, user);
    }
  }

  getUserAndPassword(){
    let user:any = localStorage.getItem(this.userKey);
    if(user) return JSON.parse(atob(user));
    else undefined;
  }

  saveUser(userData:any){
    if(userData.token){
      let user:USER = jwtDecode(userData.token);
      user.token = userData.token;
      localStorage.setItem( this.storageKey, btoa(JSON.stringify(user)) );
      this.setCurrentUser(user);
      this.isLoggedIn = true;
    }
  }

  setCurrentUser(user:USER | null){
    if(JSON.stringify(user) == JSON.stringify(this.currentUserSubject.value)) return
    this.currentUserSubject.next(user);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  checkTokenExpired(decodedUser:any):boolean{
    let currentTime = new Date().getTime();
    let tokenExpTime = decodedUser.exp * 1000;
    let isTokenExp:any = (tokenExpTime - 2000 > currentTime) ? false : true;
    return isTokenExp;
  }

  logoutUser(){
    this.resetUser();
    this.router.navigate(['/verifyUser']);
    this.toastr.success('User Logged Out!');
  }

  resetUser(){
    localStorage.removeItem(this.storageKey);
    // localStorage.removeItem(this.userKey);
    this.setCurrentUser(null);
    this.isLoggedIn = false;
  }

  get getUserFromStorage():any{
    const storedUser:any = localStorage.getItem(this.storageKey);
    if(storedUser) return atob(storedUser);
  }
}
